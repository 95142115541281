@import '@angular/material/theming';
@import "styles/theme";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}





.photo-dialog .mat-dialog-container {
  padding: 16px;
  overflow: hidden;
}


@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font-size: 16px;
  }
}

.mat-raised-button {
  border-radius: 8px !important;
}
.mat-flat-button {
  border-radius: 8px !important;
}
.mat-stroked-button {
  border-radius: 8px !important;
}


.mat-checkbox-background, .mat-checkbox-frame {
  border-radius: 4px !important;
}


.hidden-block {
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 0;
}

.visible-block {
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 1;
}

.service-select-panel {
  max-height: 420px !important;
}

//theme styles

