@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();
/* ======== Angular material custom themes ======== */
//PALETTE

.light-driver {
  // *****Light Theme*****
  $light-driver-palette: (
    50: #D2FFDA,
    100: #A9E5C3,
    200: #8DE197,
    300: #62D173,
    400: #0FC659,
    500: #00B93D,
    600: #00AA34,
    700: #009628,
    800: #00861C,
    900: #F4F7F9,
    A100: #425B6B,
    A200: #758E9E,
    A400: #111416,
    A700: #EEDC37,
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: rgba(black, 0.87),
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(black, 0.87),
      A200: rgba(black, 0.87),
      A400: rgba(black, 0.87),
      A700: rgba(black, 0.87),
    )
  );
  $light-driver-primary: mat.define-palette($light-driver-palette, 400);
  $light-driver-accent: mat.define-palette($light-driver-palette, 400);
  //$ios-warn: mat.define-palette(mat.$green-palette);
  $driver-light-theme: mat.define-light-theme((
    color: (
      primary: $light-driver-primary,
      accent: $light-driver-accent
    )
  ));

  .mat-raised-button.mat-button-disabled.mat-button-disabled {
    background-color: mat.get-color-from-palette($light-driver-palette, 100) !important;
    color: white !important;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: mat.get-color-from-palette($light-driver-palette, 900) !important;
  }
  .mat-form-field-label-wrapper .mat-form-field-label {
    color: mat.get-color-from-palette($light-driver-palette, A100);
  }
  .mat-select-arrow-wrapper .mat-select-arrow {
    color: mat.get-color-from-palette($light-driver-palette, A100);
  }
  .subtitle-theme-color {
    color: mat.get-color-from-palette($light-driver-palette, A200);
  }
  .edit-theme-color {
    color: mat.get-color-from-palette($light-driver-palette, A700);
  }
  .text-theme-color {
    color: mat.get-color-from-palette($light-driver-palette, A100);
  }
  .document-dialog .mat-dialog-container {
    padding: 16px 24px;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($light-driver-palette, 400);
      border-radius: 16px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(66, 91, 107, 0.08);
    }
  }
  .select-dialog .mat-dialog-container {
    background: mat.get-color-from-palette($light-driver-palette, 900);
    padding: 16px 24px;
    .search-input {
      padding: 12px 16px;
      border-radius: 8px;
      background: white;
      box-sizing: border-box;
      width: inherit;
    }
    .selection-item {
      padding: 12px 16px;
      margin-bottom: 1px;
      background: white;
      color: mat.get-color-from-palette($light-driver-palette, A400);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($light-driver-palette, 400);
      border-radius: 16px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: mat.get-color-from-palette($light-driver-palette, 900);
    }
  }
  .background-theme {
    background-color: white;
  }
  .background-theme-secondary {
    background-color: #EEF1F3
  }
  input::placeholder {
    color: mat.get-color-from-palette($light-driver-palette, A200) !important;
  }
  .mat-select-panel.custom-select {
    max-height: 340px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($light-driver-palette, 400);
      border-radius: 16px;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(66, 91, 107, 0.08);
    }
  }
  //@include mat.core-theme($ios-light-theme);
  @include mat.all-component-themes($driver-light-theme);
}

.dark-driver {
  //*****Dark Theme*****
  $dark-driver-palette: (
    50: #D2FFDA,
    100: #00670B,
    200: #8DE197,
    300: #62D173,
    400: #0FC659,
    500: #00B93D,
    600: #00AA34,
    700: #009628,
    800: #00861C,
    900: #1F2227,
    A100: #425B6B,
    A200: #758E9E,
    A400: #111416,
    A700: #EEDC37,
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: rgba(black, 0.87),
      400: rgba(black, 0.87),
      500: rgba(black, 0.87),
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(black, 0.87),
      A200: rgba(black, 0.87),
      A400: rgba(black, 0.87),
      A700: rgba(black, 0.87),
    )
  );
  $dark-driver-primary: mat.define-palette($dark-driver-palette, 400);
  $dark-driver-accent: mat.define-palette($dark-driver-palette, 400);
  $background-color: map_get($dark-driver-palette, A400);
  $driver-dark-theme: mat.define-dark-theme((
    color: (
      primary: $dark-driver-primary,
      accent: $dark-driver-accent
    )
  ));


  .mat-raised-button.mat-button-disabled.mat-button-disabled {
    background-color: mat.get-color-from-palette($dark-driver-palette, 100) !important;
    color: #111416 !important;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: mat.get-color-from-palette($dark-driver-palette, 900) !important;
  }
  .mat-form-field-label-wrapper .mat-form-field-label {
    color: mat.get-color-from-palette($dark-driver-palette, A200);
  }
  .mat-select-arrow-wrapper .mat-select-arrow {
    color: mat.get-color-from-palette($dark-driver-palette, A200);
  }
  .subtitle-theme-color {
    color: mat.get-color-from-palette($dark-driver-palette, A200);
  }
  .edit-theme-color {
    color: mat.get-color-from-palette($dark-driver-palette, A700);
  }
  .text-theme-color {
    color: mat.get-color-from-palette($dark-driver-palette, A200);
  }
  .document-dialog .mat-dialog-container {
    padding: 16px 24px;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($dark-driver-palette, 400);
      border-radius: 16px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(66, 91, 107, 0.08);
    }
  }
  .select-dialog .mat-dialog-container {
    background: mat.get-color-from-palette($dark-driver-palette, 900);
    padding: 16px 24px;
    .search-input {
      padding: 12px 16px;
      border-radius: 8px;
      background: mat.get-color-from-palette($dark-driver-palette, A400);;
      box-sizing: border-box;
      width: inherit;
    }
    .selection-item {
      padding: 12px 16px;
      margin-bottom: 1px;
      background: mat.get-color-from-palette($dark-driver-palette, A400);
      color: white;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($dark-driver-palette, 400);
      border-radius: 16px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: mat.get-color-from-palette($dark-driver-palette, 900);
    }
  }

  .background-theme {
    background-color: mat.get-color-from-palette($dark-driver-palette, A400) !important;
  }
  .background-theme-secondary {
    background-color: mat.get-color-from-palette($dark-driver-palette, 900) !important;
  }
  .mat-dialog-container {
    background-color: mat.get-color-from-palette($dark-driver-palette, 900) !important;
  }
  input::placeholder {
    color: mat.get-color-from-palette($dark-driver-palette, A200) !important;
  }
  .mat-select-panel.custom-select {
    max-height: 340px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($dark-driver-palette, 400);
      border-radius: 16px;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(66, 91, 107, 0.08);
    }
  }
  .app-container {
    height: 100%;
    width: 100%;
    background-color: mat.get-color-from-palette($dark-driver-palette, A400) !important;
  }
  //@include mat.core-theme($ios-dark-theme);
  @include mat.all-component-themes($driver-dark-theme);
}




